// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// var moment = require('moment');
// window.moment = moment

require('datatables.net-bs4');
require('sidebar-menu');
require('admin-script');
require('bootstrap');
require('custom-card');
// import { TabulatorFull as Tabulator } from 'tabulator-tables';
// window.Tabulator = Tabulator;


const feather = require("feather-icons");
var ordersTable = null;
var shipmentsTable = null;
var historyTable = null;
var completeOrdersTable = null;
var picklistTable = null;
var productsTable = null;

document.addEventListener("turbolinks:load", function () {
  feather.replace();

  if ($("#system-history").length) {
    historyTable = $("#system-history").DataTable({
      "order": [[1, "desc"]],
      "autoWidth": true,
      "fnCreatedRow": function (nRow, aData, iDataIndex) {
        //   $(nRow).children("td").css("overflow", "hidden");
        $(nRow).children("td").css("white-space", "nowrap");
        //   $(nRow).children("td").css("text-overflow", "ellipsis");
        //   $(nRow).children("td").css("max-width", "100px");
      },
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, 'All']
      ],
      "pageLength": 25
    });
  }

  if ($("#open-orders").length) {
    ordersTable = $("#open-orders").DataTable({
      "order": [[8, "desc"]],
      "fnCreatedRow": function (nRow, aData, iDataIndex) {
        $(nRow).children("td").css("overflow", "hidden");
        $(nRow).children("td").css("white-space", "nowrap");
        $(nRow).children("td").css("text-overflow", "ellipsis");
        $(nRow).children("td").css("max-width", "100px");
      },
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, 'All']
      ],
      "pageLength": 25
    });
  }

  if ($("#shipments-table").length) {
    shipmentsTable = $("#shipments-table").DataTable({
      // "order": [[8, "desc"]],
      // "fnCreatedRow": function( nRow, aData, iDataIndex ) {
      //   $(nRow).children("td").css("overflow", "hidden");
      //   $(nRow).children("td").css("white-space", "nowrap");
      //   $(nRow).children("td").css("text-overflow", "ellipsis");
      //   $(nRow).children("td").css("max-width", "100px");
      // },
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, 'All']
      ],
      "pageLength": 25
    });
  }

  if ($("#complete-orders").length) {
    completeOrdersTable = new Tabulator("#complete-orders", {
      layout: "fitColumns",
      pagination: "local",
      paginationSize: 20,
      paginationSizeSelector: [10, 20, 50, 100],
      paginationCounter: "rows",
      movableColumns: true,
      columns: [
        { title: "Order #", formatter: "html", sorter: "string", headerFilter: "input" },
        { title: "PO #", formatter: "html", sorter: "string", headerFilter: "input" },
        { title: "Sales Order #", formatter: "html", sorter: "string", headerFilter: "input" },
        {
          title: "Amount", formatter: "money", formatterParams: {
            decimal: ".",
            thousand: ",",
            symbol: "$",
            symbolAfter: "",
            negativeSign: true,
            precision: 2,
          }, sorter: "string", headerFilter: "input", topCalc: "sum", topCalcFormatter: "money", topCalcFormatterParams: {
            decimal: ".",
            thousand: ",",
            symbol: "$",
            symbolAfter: "",
            negativeSign: true,
            precision: 2,
          }
        },
        { title: "Shipped From", formatter: "html", sorter: "string", headerFilter: "input" },
        { title: "Requested By", formatter: "html", sorter: "string", headerFilter: "input" },
        { title: "Account", formatter: "html", sorter: "string", headerFilter: "input" },
        { title: "Items", formatter: "html", sorter: "string", headerFilter: "input" },
        { title: "Tracking", formatter: "html", sorter: "string", headerFilter: "input" },
        { title: "Shipment Type", formatter: "html", sorter: "string", headerFilter: "input" },
        { title: "Entered On", formatter: "html", sorter: "date", headerFilter: "input" },
        { title: "Shipped On", formatter: "html", sorter: "date", headerFilter: "input" },
        { title: "Required By", formatter: "html", sorter: "date", headerFilter: "input" },

      ]
    });
    $('.download-csv').click(function (event) {
      event.preventDefault();
      completeOrdersTable.download("csv", "exported.csv");
    });
  }

  // if ($("#complete-orders").length) {
  //   completeOrdersTable = $("#complete-orders").DataTable({
  //     "order": [[8, "desc"]],
  //     "fnCreatedRow": function (nRow, aData, iDataIndex) {
  //       $(nRow).children("td").css("overflow", "hidden");
  //       $(nRow).children("td").css("white-space", "nowrap");
  //       $(nRow).children("td").css("text-overflow", "ellipsis");
  //       $(nRow).children("td").css("max-width", "100px");
  //     },
  //     "lengthMenu": [
  //       [10, 25, 50, -1],
  //       [10, 25, 50, 'All']
  //     ],
  //     "pageLength": 25
  //   });
  // }

  if ($("#partial-orders").length) {
    completeOrdersTable = $("#partial-orders").DataTable({
      "order": [[8, "desc"]],
      "fnCreatedRow": function (nRow, aData, iDataIndex) {
        $(nRow).children("td").css("overflow", "hidden");
        $(nRow).children("td").css("white-space", "nowrap");
        $(nRow).children("td").css("text-overflow", "ellipsis");
        $(nRow).children("td").css("max-width", "100px");
      },
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, 'All']
      ],
      "pageLength": 25
    });
  }

  if ($("#products-list").length) {
    productsTable = $("#products-list").DataTable({
      "order": [[1, "desc"], [2, "desc"]],
      "fnCreatedRow": function (nRow, aData, iDataIndex) {
        $(nRow).children("td").css("overflow", "hidden");
        $(nRow).children("td").css("white-space", "nowrap");
        $(nRow).children("td").css("text-overflow", "ellipsis");
        $(nRow).children("td").css("max-width", "100px");
      },
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, 'All']
      ],
      "pageLength": 25
    });
  }

  if ($("#pick-list-table").length) {
    picklistTable = $("#pick-list-table").DataTable({
      "order": [[3, "asc"], [4, "asc"]],
      "fnCreatedRow": function (nRow, aData, iDataIndex) {
        $(nRow).children("td").css("overflow", "hidden");
        $(nRow).children("td").css("white-space", "nowrap");
        $(nRow).children("td").css("text-overflow", "ellipsis");
        $(nRow).children("td").css("max-width", "100px");
      },
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, 'All']
      ],
      "pageLength": 25
    });
  }

  $(".quantity-shipped").change(function () {
    if ($(this).val() != $(this).data("required")) {
      $(this).addClass("is-invalid");
    }
    else {
      $(this).removeClass("is-invalid");
    }

  });


})

document.addEventListener("turbolinks:before-cache", function () {
  if (ordersTable !== null) {
    ordersTable.destroy();
    ordersTable = null;
  }
  if (shipmentsTable !== null) {
    shipmentsTable.destroy();
    shipmentsTable = null;
  }
  if (completeOrdersTable !== null) {
    completeOrdersTable.destroy();
    completeOrdersTable = null;
  }
  if (picklistTable !== null) {
    picklistTable.destroy();
    picklistTable = null;
  }
  if (productsTable !== null) {
    productsTable.destroy();
    productsTable = null;
  }
  if (historyTable !== null) {
    historyTable.destroy();
    historyTable = null;
  }
});